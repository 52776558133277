import React, { useState, useContext, useEffect } from 'react';
import { useNavigate  } from 'react-router-dom';
import DataContext from '../../context/DataContext';
import images from '../../Constants/image'

const ConstructionStage = () => {
  const {currentComponent, setCurrentComponent,constructionStage, setConstructionStage,
    floors, setFloors,setDirection,renovation, setRenovation,buildingType} = useContext(DataContext);
  const [stage, setStage] = useState(constructionStage);
  //const [numFloors, setNumFloors] = useState(1);
  const navigate = useNavigate ();

  const [isHighlighted, setIsHighlighted] = useState(true);

  useEffect(() => {

   // setNumFloors(floors);

    // Target the div outside the root element
    return SetBackground();
  }, [isHighlighted]);

  function SetBackground() {
    const outsideDiv = document.getElementById('bgsection');
  
    // Update the class of the outside div
    if (outsideDiv) {
      //if (isHighlighted) {
        outsideDiv.classList.add('screen2-bg');
      //} else {
      //  outsideDiv.classList.remove('screen2-bg');
     // }
    }
  
    // Clean up when the component unmounts
    return () => {
      if (outsideDiv) {
        outsideDiv.classList.remove('screen2-bg');
      }
    };
  }

  const handleSelection = () => {
    setConstructionStage(stage);
   // setFloors(numFloors);
    setDirection('next');
    setCurrentComponent('addspaces');
    //navigate('/add-spaces');
  };

  const allFloorNumbers = [1, 2, 3, 4, 5, 6];
  const maxFloors = buildingType === 'apar' ? 2 : allFloorNumbers.length;
  const availableFloors = allFloorNumbers.slice(0, maxFloors);

  return (
    <>
    <div class="container">
      <div class="text-wrapper-4">Progress of Construction</div>
      <div class="group">

        <div className={`house ${stage === 'ytst' && 'active'}`} onClick={() => setStage('ytst')}>
        <input type="radio" id="ytst" name="constructionStage" checked={stage === 'ytst'}/>
        <div class="animate-image"> 
          <img class="frame-6 normal-img" src={`${images.yetstart}`} alt="House Icon" />
          <img class="frame-6 hover-img" src={`${images.yetstarthover}`} alt="House Icon" />
        </div>
        <label for="house" class="text-wrapper-5">Yet to Start</label>
        </div>

        <div className={`house ${stage === 'inpr' && 'active'}`} onClick={() => setStage('inpr')}>
        <input type="radio" id="inpr" name="constructionStage" checked={stage === 'inpr'}/>
        <div class="animate-image"> 
          <img class="frame-6 normal-img" src={`${images.progress}`} alt="House Icon" />
          <img class="frame-6 hover-img" src={`${images.progresshover}`} alt="House Icon" />
        </div>
        <label for="house" class="text-wrapper-5">In Progress</label> 
        </div>

        <div className={`house ${stage === 'alco' && 'active'}`} onClick={() => setStage('alco')}>
        <input type="radio" id="alco" name="constructionStage" checked={stage === 'alco'}/>        
        <div class="animate-image"> 
          <img class="frame-6 normal-img" src={`${images.almostcomplete}`} alt="House Icon" />
          <img class="frame-6 hover-img" src={`${images.almostcompletehover}`} alt="House Icon" />
        </div>      
        <label for="house" class="text-wrapper-5">Renovating</label> 
        </div>

        <div class="house" className={`house ${stage === 'alre' && 'active'}`} onClick={() => setStage('alre')}>
        <input type="radio" id="alre" name="constructionStage" checked={stage === 'alre'}/>        
        <div class="animate-image"> 
          <img class="frame-6 normal-img" src={`${images.industrial}`} alt="House Icon" />
          <img class="frame-6 hover-img" src={`${images.industrialhover}`} alt="House Icon" />
        </div>        
        <label for="house" class="text-wrapper-5">Already Residing</label> 
        </div>
      </div>
      
      {/* <div class="form-container next-box">
        <div class="item-box-new">
        <div class="form-group">
      <label for="floors">Number of floors</label>     
      <select onChange={(e) => setNumFloors(parseInt(e.target.value, 10))} value={numFloors}>
        {availableFloors.map((floorNumber) => (
        <option key={floorNumber} value={floorNumber}>
          {floorNumber}
        </option>
        ))}
      </select> 
      </div>
    
      </div>
      </div> */}

      <div class="pagination">
              <div class="item-box cstm-item" onClick={() => {setDirection('back');setCurrentComponent('buildingtype')}}>1</div>
              <div class="item-box cstm-item active">2</div>
              <div class="item-box" onClick={() => handleSelection()}>3</div>
              <div onClick={() => handleSelection()} class="item-box">Next <img class="img" src={`${images.next}`} alt="Next Icon" /></div>
      </div>

    </div>

    </>
  );
};

export default ConstructionStage;


