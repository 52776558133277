import React, { Fragment, useState, useEffect , useContext } from 'react'
import DataContext from '../../context/DataContext';
import { Image, Text, View, Page, Document, StyleSheet } from '@react-pdf/renderer';
import logo from '../../Assets/img/logo.png'
import { generateInvoiceNumber, getCurrentDateTime,formatCurrency, comparator,generateId } from '../../Common/utils'


  const InvoiceSumAndBom = ({selecteditems, selectedbrand,selectedbrandname, totalprice, areaControllingItems, essentialItem }) => {

   // const {totalPrice} = useContext(DataContext);

    const [quoteNumber, setQuoteNumber] = useState('');
    const [currentDateTime, setCurrentDateTime] = useState('');

    const [bom, setBom] = useState([]);
    const [miscellaneousItems, setMiscellaneousItems] = useState([]);
    const [optionalItems, setOptionalItems] = useState([]);

    useEffect(() => {
        setQuoteNumber(generateInvoiceNumber());
        setCurrentDateTime(getCurrentDateTime());
      }, []);



    const reciept_data = {
        "id": "642be0b4bbe5d71a5341dfb1",
        "name":"User Name",
        "address": "User Address",
        "items": [
          {
            "id": 1,
            "desc": "First Floor",
            "qty": 8,
            "price": 179.25
          },
          {
            "id": 2,
            "desc": "Bedroom",
            "qty": 9,
            "price": 107.78
          },
          {
            "id": 3,
            "desc": "Kitchen",
            "qty": 4,
            "price": 181.62
          },
          {
            "id": 4,
            "desc": "Indoor Motion Sensor",
            "qty": 4,
            "price": 604.55
          },
          {
            "id": 5,
            "desc": "Central Touch Screen",
            "qty": 6,
            "price": 687.08
          }
        ]
      }

    const styles = StyleSheet.create({
        page: {fontSize: 11,paddingTop: 20,paddingLeft: 40,paddingRight: 40,lineHeight: 1.5,flexDirection: 'column' },

        spaceBetween : {flex : 1,flexDirection: 'row',alignItems:'center',justifyContent:'space-between',color: "#3E3E3E" },

        titleContainer: {flexDirection: 'row',marginTop: 24},
        
        logo: { width: 90 },

        reportTitle: {  fontSize: 16,  textAlign: 'center' },

        addressTitle : {fontSize: 11,fontStyle: 'bold'}, 
        
        invoice : {fontWeight: 'bold',fontSize: 20},
        
        invoiceNumber : {fontSize: 11,fontWeight: 'bold'}, 
        
        address : { fontWeight: 400, fontSize: 10},
        
        theader : {marginTop : 20,fontSize : 10,fontStyle: 'bold',paddingTop: 4 ,paddingLeft: 7 ,flex:1,height:20,backgroundColor : '#DEDEDE',borderColor : 'whitesmoke',borderRightWidth:1,borderBottomWidth:1},

        theaderCenter : {textAlign: 'center', marginTop : 20,fontSize : 10,fontStyle: 'bold',paddingTop: 4 ,paddingLeft: 7 ,flex:1,height:20,backgroundColor : '#DEDEDE',borderColor : 'whitesmoke',borderRightWidth:1,borderBottomWidth:1},

        theaderRight : {textAlign: 'right', marginTop : 20,fontSize : 10,fontStyle: 'bold',paddingTop: 4 ,paddingRight: 7 ,flex:1,height:20,backgroundColor : '#DEDEDE',borderColor : 'whitesmoke',borderRightWidth:1,borderBottomWidth:1},

        theaderTotalRight : {textAlign: 'right', fontSize : 10,fontStyle: 'bold',paddingTop: 4 ,paddingRight: 7 ,flex:1,height:20,backgroundColor : '#DEDEDE',borderColor : 'whitesmoke',borderRightWidth:1,borderBottomWidth:1},

        theaderTotalLeft : {textAlign: 'left', fontSize : 10,fontStyle: 'bold',paddingTop: 4 ,paddingLeft: 7 ,flex:1,height:20,backgroundColor : '#DEDEDE',borderColor : 'whitesmoke',borderRightWidth:1,borderBottomWidth:1},

        typeheader : {marginTop : 5,fontSize : 9,fontStyle: 'bold',paddingTop: 4 ,paddingLeft: 7 ,flex:1,height:20,backgroundColor : '#f1f1f1',borderColor : 'whitesmoke',borderRightWidth:1,borderBottomWidth:1},


        theader2 : { flex:2, borderRightWidth:1, borderBottomWidth:1},

        tbody:{ fontSize : 9, paddingTop: 4 , paddingLeft: 7 , flex:1, borderColor : 'whitesmoke', borderRightWidth:1, borderBottomWidth:1},

        total:{ fontStyle: 'bold', fontSize : 9, paddingTop: 4 , paddingLeft: 7 , flex:1.5, borderColor : 'whitesmoke', borderBottomWidth:1},

        tbody2:{ flex:2, borderRightWidth:1, },

        tbodyCenter:{ textAlign: 'center', fontSize : 9, paddingTop: 4 , paddingLeft: 7 , flex:1, borderColor : 'whitesmoke', borderRightWidth:1, borderBottomWidth:1},
        
        tbodyRight:{ textAlign: 'right', fontSize : 9, paddingTop: 4 , paddingRight: 7 , flex:1, borderColor : 'whitesmoke', borderRightWidth:1, borderBottomWidth:1},
       
    });

    const InvoiceTitle = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <Image style={styles.logo} src={logo} />
                <Text style={styles.reportTitle}>Automateo Enterprises</Text>
            </View>
        </View>
    );

    const SummaryTitle = () => (
      <View style={styles.titleContainer}>
          <View style={styles.spaceBetween}>
              <Text style={styles.reportTitle}>Summary</Text>
          </View>
      </View>
  );

  const BomTitle = () => (
    <View style={styles.titleContainer}>
        <View style={styles.spaceBetween}>
            <Text style={styles.reportTitle}>BOM (Bill of materials)</Text>
        </View>
    </View>
);

    const Address = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View>
                    <Text style={styles.invoice}>Quotation </Text>
                    <Text style={styles.invoiceNumber}>Quote number: {quoteNumber} </Text>
                </View>
                <View>
                    <Text style={styles.addressTitle}>Plot No. 409, </Text>
                    <Text style={styles.addressTitle}>Industrial Area Phase 9,</Text>
                    <Text style={styles.addressTitle}>Mohali, Punjab 160062.</Text>
                    <Text style={styles.addressTitle}>email: sales@magkraftndt.com</Text>
                    <Text style={styles.addressTitle}>mobile: +91 92162 88466</Text>
                </View>
            </View>
        </View>
    );

    const UserAddress = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View style={{maxWidth : 200}}>
                    <Text style={styles.addressTitle}> </Text>
                    <Text style={styles.address}>
                        {reciept_data.name}
                    </Text>
                    <Text style={styles.address}>
                        {reciept_data.address}
                    </Text>
                </View>
                <Text style={styles.addressTitle}>{currentDateTime}</Text>
            </View>
        </View>
    );


    const TableHeadSummary = () => (
        <View style={{ width:'100%', flexDirection :'row', marginTop:10}}>
            <View style={[styles.theader, styles.theader2]}>
                <Text >Items ({selectedbrandname})</Text>   
            </View>           
            <View style={styles.theaderCenter}>
                <Text>Quantity</Text>   
            </View>
            <View style={styles.theaderRight}>
                <Text>Unit Price</Text>   
            </View>
            <View style={styles.theaderRight}>
                <Text>Amount (INR)</Text>   
            </View>
        </View>
    );

    const renderItemsWithHeaders = (selectedItems, selectedBrandName) => {
        let headers = {
          efit: false,
          dait: false,
          opit: false,
          miit: false,
        };

        if(!selectedItems)
        {
            return;
        }
      
        return selectedItems?.sort(comparator).map((item, index) => {
          let header = null;
      
          if (!headers[item.ItemTypeCode]) {
            switch (item.ItemTypeCode) {
              case 'efit':
                header = (
                    <View key={'header-efit'} style={{ width:'100%', flexDirection :'row'}}>
                        <View style={styles.typeheader}>
                           <Text colSpan="4">Essential Items</Text>
                      </View>
                    </View>
                  );
                break;
              case 'dait':
                header = (                    
                    <View key={'header-efit'} style={{ width:'100%', flexDirection :'row'}}>
                        <View style={styles.typeheader}>
                           <Text colSpan="4">Area</Text>
                      </View>
                    </View>
                  );
                break;
              case 'opit':
                header = (
                    <View key={'header-efit'} style={{ width:'100%', flexDirection :'row'}}>                     
                      <View style={styles.typeheader}>
                         <Text colSpan="4">Optional Products</Text>
                      </View>
                    </View>
                  );
                break;
              case 'miit':
                header = (
                    <View key={'header-efit'} style={{ width:'100%', flexDirection :'row'}}>                      
                      <View style={styles.typeheader}>
                       <Text colSpan="4">Miscellaneous Items</Text>
                      </View>
                    </View>
                  );
                break;
              default:
                break;
            }
            headers[item.ItemTypeCode] = true;
          }
      
          return (
            <React.Fragment key={index}>
              {header}
              <View style={{ width:'100%', flexDirection :'row'}}>
                 <View style={[styles.tbody, styles.tbody2]}>
                     <Text >{item.ItemName}</Text>   
                 </View>
                 <View style={styles.tbodyCenter}>
                     <Text>{item.Quantity}</Text>   
                 </View>
                 <View style={styles.tbodyRight}>
                     <Text>{formatCurrency(item[selectedBrandName])} </Text>   
                 </View>                 
                 <View style={styles.tbodyRight}>
                     <Text>{formatCurrency(item[selectedBrandName] * item.Quantity)}</Text>   
                 </View>
             </View>
            </React.Fragment>
          );
        });
      };

    const TableBodySummary = () => (        
        renderItemsWithHeaders(selecteditems, selectedbrandname)
     );

   

    const TableTotalSummary = () => (
        <View style={{ width:'100%', flexDirection :'row'}}>
            <View style={styles.theaderTotalLeft}>
                <Text>Total</Text>   
            </View>
            <View style={styles.theaderTotalRight}>
                <Text colSpan="3">                
                    {formatCurrency(totalprice)}
                </Text>  
            </View>
        </View>
    );


    const TableHeadBom = () => (
      <View style={{ width:'100%', flexDirection :'row', marginTop:10}}>
          <View style={[styles.theader, styles.theader2]}>
              <Text >Name</Text>   
          </View>           
          <View style={styles.theaderRight}>
              <Text>Quantity</Text>   
          </View>
      </View>
  );

  const EssentialItemBom = () => (
    <View style={{ width:'100%', flexDirection :'row'}}>
               <View style={[styles.tbody, styles.tbody2]}>
               <Text>{essentialItem?.ItemName ?? 'No Item Name'}</Text>
               </View>
               <View style={styles.tbodyRight}>
                   <Text>1</Text>   
               </View>              
           </View>
);


  const renderAreaControllingItemsBom = () => {

    const newBom = areaControllingItems.reduce((acc, controlItem) => {
      const matchingItems = selecteditems.filter(
          (selectedItem) =>
              selectedItem.ItemCode === controlItem.AreaItemCode &&
              controlItem.BrandCode === selectedbrand //selected brand
      );

      const totalQuantity = matchingItems.reduce(
          (sum, item) => sum + item.Quantity, 0
      );

      if (totalQuantity > 0) {

            const existingItem = acc.find(item => item.Name === controlItem.ControllingItemName);
            
            if (existingItem) {
                // If the item with the same name already exists, sum the quantities
                existingItem.Quantity += controlItem.ControllingItemQuantity * totalQuantity;
            } else {
                // Otherwise, add a new item to the bom
                acc.push({
                    Id: generateId(),
                    Name: controlItem.ControllingItemName,
                    Quantity: controlItem.ControllingItemQuantity * totalQuantity
                });
            }
      }

      return acc;
  }, []);
    
      return newBom?.map((item, index) => {
       
        return (
          <React.Fragment key={index}>
            <View style={{ width:'100%', flexDirection :'row'}}>
               <View style={[styles.tbody, styles.tbody2]}>
                   <Text >{item.Name}</Text>   
               </View>
               <View style={styles.tbodyRight}>
                   <Text>{item.Quantity}</Text>   
               </View>              
           </View>
          </React.Fragment>
        );
      });
    };

    const renderOptionalItemsBom = () => {
  
      const opitItems = selecteditems.filter(
        (selectedItem) =>
            selectedItem.ItemTypeCode === 'opit'
      );
  
      
        return opitItems?.map((item, index) => {
         
          return (
            <React.Fragment key={index}>
              <View style={{ width:'100%', flexDirection :'row'}}>
                 <View style={[styles.tbody, styles.tbody2]}>
                     <Text >{item.ItemName}</Text>   
                 </View>
                 <View style={styles.tbodyRight}>
                     <Text>{item.Quantity}</Text>   
                 </View>              
             </View>
            </React.Fragment>
          );
        });
      };

    const renderMiscellaneousItemsBom = () => {

        const miisItems = selecteditems.filter(
          (selectedItem) =>
              selectedItem.ItemTypeCode === 'miit'
        );
        
          return miisItems?.map((item, index) => {
           
            return (
              <React.Fragment key={index}>
                <View style={{ width:'100%', flexDirection :'row'}}>
                   <View style={[styles.tbody, styles.tbody2]}>
                       <Text >{item.ItemName}</Text>   
                   </View>
                   <View style={styles.tbodyRight}>
                       <Text>{item.Quantity}</Text>   
                   </View>              
               </View>
              </React.Fragment>
            );
          });
        };

        const TableBodyBom = () => (
          <>
              {renderAreaControllingItemsBom()}
              {renderOptionalItemsBom()}
              {renderMiscellaneousItemsBom()}
          </>
      );

 

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <InvoiceTitle  />
                <Address/>
                <UserAddress/>
                <SummaryTitle/>
                <TableHeadSummary/>
                <TableBodySummary/>                
                <TableTotalSummary/>

            </Page>
            <Page size="A4" style={styles.page}>
                <InvoiceTitle  />
                <Address/>
                <UserAddress/> 
                <BomTitle/>
                <TableHeadBom/>
                <EssentialItemBom/>
                <TableBodyBom/>  
            </Page>
        </Document>
          
    )
}

export default InvoiceSumAndBom