import { createContext, useState } from 'react';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [direction, setDirection] = useState('next');
  const [currentComponent, setCurrentComponent] = useState('buildingtype');
  const [buildingType, setBuildingType] = useState('hous');
  const [constructionStage, setConstructionStage] = useState('ytst');
  const [floors, setFloors] = useState(1);
  const [renovation, setRenovation] = useState(false);
  const [floorDetails, setFloorDetails] = useState([]);
  // const [floorDetails, setFloorDetails] = useState([
  //   { AC: false, heating: 'central', rooms: 3 },
  //   { AC: true, heating: 'independent', rooms: 2 },
  //   // other floors...
  // ]);
  const [centralAC, setCentralAC] = useState(false);
  const [centralWaterHeating, setCentralWaterHeating] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [userDetails, setUserDetails] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('wohn');
  const [selectedBrandName, setSelectedBrandName] = useState('Wohnux');
  const [blobpdf, setBlobpdf] = useState(null);
  const [areaControllingItems, setAreaControllingItems] = useState([]);

  return (
    <DataContext.Provider value={{
      direction, setDirection,
      currentComponent, setCurrentComponent,
      buildingType, setBuildingType,
      constructionStage, setConstructionStage,
      floors, setFloors,
      renovation, setRenovation,
      floorDetails, setFloorDetails,
      centralAC, setCentralAC,
      centralWaterHeating, setCentralWaterHeating,
      totalPrice, setTotalPrice,
      userDetails, setUserDetails,
      selectedItems, setSelectedItems,
      selectedBrand, setSelectedBrand,
      selectedBrandName, setSelectedBrandName,
      blobpdf, setBlobpdf,
      areaControllingItems, setAreaControllingItems
    }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
