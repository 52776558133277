import React, { useContext, useState, useEffect} from 'react'
import { CgFileDocument } from 'react-icons/cg'
import { HiOutlineDownload, HiOutlinePrinter } from 'react-icons/hi'
import { FiShare2 } from 'react-icons/fi'
import { BlobProvider, PDFDownloadLink } from '@react-pdf/renderer'
import DataContext from '../../context/DataContext';
import InvoiceSumAndBom from './InvoiceSumAndBom'
import { saveAs } from "file-saver";

const PdfCardForSumAndBom = ({ title, selecteditems, selectedbrand, selectedbrandname, totalprice, areaControllingItems, essentialItem }) => {

    // State to store the blob value
    const [blobGenerated, setBlobGenerated] = useState(null);

    const [blobSize, setBlobSize] = useState(null);

    const {blobpdf, setBlobpdf } = useContext(DataContext);

    const styles = {
        container: { width: '220px', borderRadius: '5px', padding: '15px 12px', display: 'flex', flexDirection: 'column', gap: '15px', boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" },
        flex: { width: '100%', display: 'flex', gap: '5px', alignItems: 'center' },
        bold: { fontSize: '13px', fontWeight: 600 },
        thin: { fontSize: '11px', color: '#6f6f6f', fontWeight: 500 },
        btn: { borderRadius: '3px', border: '1px solid gray', display: 'flex', alignItems: 'center', gap: '2px', padding: '3px', fontSize: '11px', color: '#4f4f4f', fontWeight: 600, cursor: 'pointer', userSelect: 'none' }
    }


    const handleShare = async (blob) => {
        await saveAs(blob, `quote.pdf`);
        window.location.href = `mailto:?subject=${encodeURIComponent(`Invoice`)}&body=${encodeURIComponent(`Kindly find attached invoice`)}`;
    }

    // Use BlobProvider and set blob on component load
    useEffect(() => {
        console.log("useEffect");
        if (blobGenerated) {
            console.log("useEffect in condition");
            setBlobpdf(blobGenerated); // Set the Blob for Contact Us page when the Blob is available
        }
    }, [blobGenerated, setBlobpdf]);

    return (
        <div style={styles.container}>
           
            <div style={{ ...styles.flex, ...{ justifyContent: 'space-between' } }}>

                <PDFDownloadLink document={<InvoiceSumAndBom selecteditems={selecteditems} 
                selectedbrand={selectedbrand} 
                selectedbrandname={selectedbrandname} 
                totalprice={totalprice} 
                areaControllingItems={areaControllingItems}
                essentialItem={essentialItem}
                />} fileName='quote.pdf'>
                    <div style={styles.btn}>
                        <HiOutlineDownload size={14} />
                        <span>Download</span>
                    </div>
                </PDFDownloadLink>

                <BlobProvider document={<InvoiceSumAndBom selecteditems={selecteditems} selectedbrand={selectedbrand} 
                selectedbrandname={selectedbrandname} totalprice={totalprice}
                areaControllingItems={areaControllingItems}
                essentialItem={essentialItem}
                />}>
                    {({ blob, url }) => {
                        if (blob && blobSize!==blob.size) {
                            setBlobSize(blob.size);
                            console.log("inline");
                            console.log(blob);
                            setBlobGenerated(blob); // Set the blob only once to avoid multiple renders
                        }

                        return (
                            <a href={url} target="_blank" style={styles.btn}>
                                <HiOutlinePrinter size={14} />
                                <span>Print</span>
                            </a>
                        );
                    }}
                </BlobProvider>

         


                <BlobProvider document={<InvoiceSumAndBom selecteditems={selecteditems} 
                selectedbrand={selectedbrand} selectedbrandname={selectedbrandname} totalprice={totalprice}
                areaControllingItems={areaControllingItems}
                essentialItem={essentialItem}/>}>
                    {({ url, blob }) => (
                        <div style={styles.btn} onClick={() => handleShare(url, blob)} >
                            <FiShare2 size={14} />
                            <span>Share</span>
                        </div>
                    )}
                </BlobProvider>


            </div>


        </div>
    )
}

export default PdfCardForSumAndBom
