import React, { useContext, useState, useEffect } from 'react';
import { useNavigate  } from 'react-router-dom';
import DataContext from '../../context/DataContext';
import images from '../../Constants/image'


const BuildingType = () => {
  const {currentComponent, setCurrentComponent, buildingType, setBuildingType, setDirection,floors, setFloors, } = useContext(DataContext);
  const navigate = useNavigate();
 // const [numFloors, setNumFloors] = useState(1);
  const [isHighlighted, setIsHighlighted] = useState(true);

  const allFloorNumbers = [1, 2, 3, 4, 5, 6];
  const maxFloors = buildingType === 'apar' ? 2 : allFloorNumbers.length;
  const availableFloors = allFloorNumbers.slice(0, maxFloors);
  
  // Floor numbers mapped to text
const floorTextMapping = {
  1: 'One',
  2: 'Two',
  3: 'Three',
  4: 'Four',
  5: 'Five',
  6: 'Six'
};

  useEffect(() => {

   // setNumFloors(floors);

  },[isHighlighted]);

  const handleSelection = (type) => {
    setBuildingType(type);
    setFloors(1);
   // setCurrentComponent('constructionstage');
   // navigate('/construction-stage');    
  };
  

  return (
    <>
    <div className="container">
          <div className="text-wrapper-4">Select Your Building Type</div>
          <div className="group">
              <div className={`house ${buildingType === 'hous' && 'active'}`} onClick={() => handleSelection('hous')}>
                  <input type="radio" id="house" name="buildingType" checked={buildingType === 'hous'}/>
                  <div className="animate-image"> 
                    <img className="frame-6 normal-img" src={`${images.house}`} alt="House Icon" />
                    <img className="frame-6 hover-img" src={`${images.househover}`} alt="House Icon" />
                  </div>
                  <label for="house" class="text-wrapper-5">House</label>
              </div>
              <div className={`apartment ${buildingType === 'apar' && 'active'}`}  onClick={() => handleSelection('apar')}>
                  <input type="radio" id="apartment" name="buildingType" checked={buildingType === 'apar'}/>
                  <div className="animate-image"> 
                    <img className="frame-6  normal-img" src={`${images.apartment}`} alt="Apartment Icon" />
                    <img className="frame-6  hover-img" src={`${images.apartmenthover}`} alt="Apartment Icon" />
                  </div>
                  <label for="apartment" class="text-wrapper-6">Apartment</label>
              </div>
              <div  className={`commercial ${buildingType === 'comm' && 'active'}`} onClick={() => handleSelection('comm')}>
                  <input type="radio" id="commercial" name="buildingType" checked={buildingType === 'comm'} />
                  <div className="animate-image"> 
                    <img className="frame-6 normal-img" src={`${images.commercial}`} alt="Commercial Icon" />
                    <img className="frame-6 hover-img" src={`${images.commercialhover}`} alt="Commercial Icon" />
                  </div>
                  <label for="commercial" class="text-wrapper-7">Commercial</label>
              </div>
              <div className={`industrial ${buildingType === 'indu' && 'active'}`} onClick={() => handleSelection('indu')}>
                  <input type="radio" id="industrial" name="buildingType" checked={buildingType === 'indu'}/>
                  <div className="animate-image"> 
                    <img className="frame-6 normal-img" src={`${images.industrial}`} alt="Industrial Icon" />
                    <img className="frame-6 hover-img" src={`${images.industrialhover}`} alt="Industrial Icon" />
                  </div>
                  <label for="industrial" class="text-wrapper-8">Industrial</label>
              </div>
          </div>

          <div class="form-container next-box">
        <div class="item-box-new">
        <div class="form-group">
      <label for="floors">Number of floors</label>     
      <select onChange={(e) => setFloors(parseInt(e.target.value, 10))} value={floors}>
        {availableFloors.map((floorNumber) => (
        <option key={floorNumber} value={floorNumber}>
          {/* {floorNumber} */}
          {floorTextMapping[floorNumber]}
        </option>
        ))}
      </select> 
      </div>
   
      </div>
      </div>
          <div className="pagination">
              <div className="item-box cstm-item active">1</div>
              <div className="item-box cstm-item" onClick={() => { setDirection('next'); setCurrentComponent('constructionstage')}}>2</div>
              <div className="item-box" onClick={() => {setDirection('next'); setCurrentComponent('addspaces')}}>3</div>
              <div className="item-box" onClick={() => {setDirection('next'); setCurrentComponent('constructionstage')}}>Next <img class="img" src={`${images.next}`} alt="Next Icon" /></div>
          </div>
      </div>
    </>
  );
};

export default BuildingType;
